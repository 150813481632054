var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"register login py-lg-5 gradiant-bg card-tm"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center align-items-center p-lg-3"},[(_vm.isError)?_c('div',{staticClass:"col-xl-5 col-lg-7 col-md-7 mx-auto"},[_c('div',{staticClass:"reset-link-main"},[_c('p',{staticClass:"mb-3"},[_vm._v("Link is either incorrect or expired.Try again!")]),_c('router-link',{attrs:{"to":"/forgetpassword"}},[_c('button',{staticClass:"btn btn-primary try-btn",attrs:{"type":"button"}},[_vm._v(" Try Again ")])])],1)]):_vm._e(),(_vm.show)?_c('div',{staticClass:"col-md-5 p-2"},[_c('div',{staticClass:"card rounded-4 shadow text-center p-3"},[_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"fs-3 fw-600"},[_vm._v("Reset Password")]),_c('p',{staticClass:"font-light-gray fs-14"},[_vm._v(" Keep your account secure with passwords")]),_c('div',{staticClass:" flat-tabs"},[_c('div',{staticClass:"content-inner"},[_c('div',{staticClass:"content-tab"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"col-12 mb-3 text-start"},[_c('div',{staticClass:"input-group",class:{
                                                    'form-group--error': _vm.$v.form.password.$error,
                                                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":_vm.type,"placeholder":"New Password","aria-label":"New Password"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon1"}},[(_vm.type == 'password')?_c('i',{staticClass:"fas fa-eye-slash",on:{"click":function($event){_vm.type = 'text'}}}):_c('i',{staticClass:"fas fa-eye",on:{"click":function($event){_vm.type = 'password'}}})])]),(_vm.submitted && !_vm.$v.form.password.required)?_c('div',{staticClass:"error"},[_vm._v(" Password is required ")]):_vm._e(),(
                                                    _vm.submitted &&
                                                    !_vm.$v.form.password.valid &&
                                                    _vm.$v.form.password.required
                                                )?_c('div',{staticClass:"error"},[_vm._v(" Password should contains at least One Uppercase, One Lowercase and One Special Character. ")]):_vm._e()]),_c('div',{staticClass:"col-12 mb-4 text-start"},[_c('div',{staticClass:"input-group",class:{
                                                    'form-group--error': _vm.$v.form.confirm_password.$error,
                                                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.confirm_password.$model),expression:"$v.form.confirm_password.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":_vm.type2,"placeholder":"Confirm Password","aria-label":"Confirm Password"},domProps:{"value":(_vm.$v.form.confirm_password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.confirm_password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon1"}},[(_vm.type2 == 'password')?_c('i',{staticClass:"fas fa-eye-slash",on:{"click":function($event){_vm.type2 = 'text'}}}):_c('i',{staticClass:"fas fa-eye",on:{"click":function($event){_vm.type2 = 'password'}}})])]),(_vm.submitted && !_vm.$v.form.confirm_password.required)?_c('div',{staticClass:"error"},[_vm._v(" Confirm Password is required ")]):_vm._e(),(
                                                    _vm.submitted && !_vm.$v.form.confirm_password.sameAsPassword
                                                )?_c('div',{staticClass:"error"},[_vm._v(" Password and Confirm Password is not match. ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[(_vm.loading)?_c('button',{staticClass:"btn btn-action w-100 border-0",attrs:{"type":"button"}},[_vm._m(0)]):_c('button',{staticClass:"btn btn-action w-100 border-0",attrs:{"type":"submit"}},[_vm._m(1)])])])])])])])])])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Reset Password")]),_c('span',[_c('i',{staticClass:"fas fa-long-arrow-right"})])])}]

export { render, staticRenderFns }